import { Layout } from "antd";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../Components/Button";
import classes from "./hero.module.css";

function HeroSection({ ...props }) {
  const navigate = useNavigate();

  useEffect(() => {
    var cards = document.querySelectorAll(`.${classes.heroSection}`);
    cards?.forEach((card) => {
      card.addEventListener("mousemove", function (e) {
        const rect = card.getBoundingClientRect();
        const left = e.clientX - rect.left;
        const top = e.clientY - rect.top;

        card.style.setProperty("--left", `${left}px`);
        card.style.setProperty("--top", `${top}px`);
      });
    });
  }, []);

  return (
    <section className={classes.heroSection} {...props}>
      <Layout className={["custom_container", classes.bgTrans].join(" ")}>
        <h1 className={`${classes.fs_110}`}>
          What's your idea? Let us build it.
        </h1>
        <p>
          Tech Flow Studio is an agency that designs and develops e-commerce,
          marketing websites, web applications, and mobile apps.
        </p>
        <div className={classes.btnsDiv}>
          <Button onClick={() => navigate("/about")}>About Us</Button>
          <Button onClick={() => navigate("/contact")}>Contact Us</Button>
        </div>
      </Layout>
    </section>
  );
}

export default HeroSection;
