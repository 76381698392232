import React from "react";
import IntroVideo from "../../assets/Intro.mp4";
// import ReactPlayer from "react-player";
import classes from "./videoSection.module.css";
import { Layout } from "antd";

function VideoSection() {
  return (
    <Layout className={["custom_container", classes.bgTrans].join(" ")}>
      <div className={classes.video}>
        {/* <ReactPlayer url={IntroVideo} muted playing loop /> */}
      </div>
    </Layout>
  );
}

export default VideoSection;
