import logo from "./logo.svg";
import "./App.css";
import "swiper/css";
import Header from "./Components/Header";
import Layout from "./Components/Layout";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import { ToastContainer } from "react-toastify";
import Demo from "./Pages/Demo";
import "react-modern-drawer/dist/index.css";
// import './locomotive.css'
import "locomotive-scroll/dist/locomotive-scroll.css";
import Services from "./Pages/Services";
import Work from "./Pages/Work";
import ScrollToTop from "./Components/ScrollToTop";

function App() {
  return (
    <div className="">
      <ToastContainer />
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services" element={<Services />} />
          <Route path="/work" element={<Work />} />
          <Route path="/demo" element={<Demo />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
