import { Col, Layout, Row } from "antd";
import React from "react";
import { toast } from "react-toastify";
import Button from "../../Components/Button";
import Input from "../../Components/Input";
import classes from "./contactUs.module.css";

function ContactUs(props) {
  return (
    <section className={classes.contactSection} {...props}>
      <Layout className={["custom_container", classes.bgTrans].join(" ")}>
        <Row
          gutter={[
            { md: 30, sm: 15, xs: 10 },
            { sm: 15, xs: 10 },
          ]}
        >
          <Col xs={24}>
            <h1 className={`${classes.aboutBigHeading}`}>
              Create with us <span className={`${classes.icon}`}>⁕</span>
            </h1>
            <a className={`${classes.contactEmail}`} href="">
              hello@techflow.com
            </a>
          </Col>
          {/* <Col md={12}>
            <h1 className={`${classes.bigHeading}`}>We're too popular right now ☺</h1>
            <h6 className={`${classes.smallHeading}`}>We tailor your ideas into reality. Come visit us and you will see that all are true.</h6>
          </Col>
          <Col md={12}>
            <Row gutter={[{}, { sm: 15, xs: 10 }]}>
              <Col xs={24} md={24}>
                <Input placeholder={"Enter name here..."} 
                label={"Name"} 
                />
              </Col>
              <Col xs={24}>
                <Input placeholder={"Enter email here..."} 
                label={"Email"} 
                />
              </Col>
              <Col xs={24}>
                <Input
                  isTextArea
                  placeholder={"Enter your message here..."}
                  label={"Message"}
                />
              </Col>
              <Col md={24}>
                <Button
                  onClick={() => {
                    toast.error(
                      "Sorry! This web is currently in development mode"
                    );
                  }}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Col> */}
        </Row>
      </Layout>
    </section>
  );
}

export default ContactUs;
