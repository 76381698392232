import React from "react";
import LayoutBox from "../../Components/Layout";
import { Col, Layout, Row } from "antd";
import classes from "./Home.module.css";
import Button from "../../Components/Button";
import Input from "../../Components/Input";
import Footer from "../../Components/Footer";
import ReactElasticCarousel from "react-elastic-carousel";
import { toast } from "react-toastify";
import WhatWeDoCard from "../../Components/WhatWeDoCard";
import Accordion from "../../Components/Accordion";
import HeroSection from "../../Sections/Hero";
import FaqsSection from "../../Sections/Faqs";
import OurServices from "../../Sections/OurServices";
import ContactUs from "../../Sections/ContactUs";
import TestimonialsSection from "../../Sections/Testimonials";
import OurPortfolio from "../../Sections/OurPortfolio";
import {
  DemoImg,
  Img1,
  Img2,
  Img3,
  Portfolio1,
  Portfolio2,
  Portfolio3,
} from "../../Constant/imagePath";
import { LocomotiveScrollProvider } from "react-locomotive-scroll";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import VideoSection from "../../Sections/VideoSection";

export default function Home() {
  const navigate = useNavigate();
  const imgs = [
    DemoImg,
    DemoImg,
    DemoImg,
    DemoImg,
    DemoImg,
    DemoImg,
    DemoImg,
    DemoImg,
  ];

  const PortfolioImgs = [
    DemoImg,
    Portfolio1,
    Portfolio2,
    Portfolio3,
    DemoImg,
    Portfolio1,
    Portfolio2,
    Portfolio3,
  ];

  const containerRef = useRef(null);
  return (
    <div className={classes.page}>
      <LayoutBox containerClass={"custom_container"}>
        <HeroSection />
        <VideoSection />

        {/* <div className={`${classes.pattern}`}>
          <h1>TECH FLOW STUDIO</h1>
        </div> */}

        {/* Hero */}
        {/* Hero */}
        {/* About */}
        <section className={classes.aboutSection}>
          <Layout className={["custom_container", classes.bgTrans].join(" ")}>
            <Row gutter={[{ md: 20 }, { md: 20, sm: 20, xs: 20 }]}>
              <Col md={24}>
                <h1 className={`${classes.aboutBigHeading}`}>
                  Few words about{" "}
                  <span className={`${classes.aboutSpan}`}>Who we are </span>
                </h1>
              </Col>

              <Col
                md={{ span: 12, order: 1 }}
                lg={6}
                xxl={4}
                sm={{ span: 24, order: 2 }}
                xs={{ span: 24, order: 2 }}
                className={classes.bigImgCol}
                // xs={24}
                // style={{ display: "flex", alignItems: "center" }}
              >
                <div
                  className={`${classes.imgDiv} ${classes.aboutBigImg} ${classes.imgOverlay}`}
                  // style={{ height: "80%" }}
                >
                  <img src={Img3} />
                </div>
              </Col>
              <Col md={0} sm={0} xs={0} xxl={7} lg={{ span: 7, order: 2 }}>
                <Row
                  gutter={[{ md: 10 }, { md: 0 }]}
                  style={{ height: "100%" }}
                >
                  <Col md={24} className={`${classes.smallImgCol}`}>
                    <div
                      className={`${classes.imgDiv} ${classes.aboutSmallImg} ${classes.imgOverlay}`}
                    >
                      <img src={Img1} />
                    </div>
                    <div
                      className={`${classes.imgDiv} ${classes.mt20} ${classes.aboutSmallImg} ${classes.imgOverlay}`}
                    >
                      <img src={Img2} />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col
                lg={11}
                xxl={13}
                md={{ span: 12, order: 3 }}
                sm={{ span: 24, order: 1 }}
                xs={{ span: 24, order: 1 }}
                className={`${classes.aboutContent}`}
              >
                {/* <h3>Who We Are?</h3> */}
                <h6 className="">
                  Techflowstudio is the ultimate software agency for website
                  development, design, app development, content writing, and
                  graphic design.{" "}
                </h6>
                <p>
                  With years of experience in the software development field,
                  our team of experts is constantly pushing the boundaries of
                  technology and design, delivering innovative and
                  cost-effective solutions.
                </p>
                <p>
                  We always challenge ourselves to come up with groundbreaking
                  approaches, and are committed to providing our clients with
                  the best service possible. With our timely delivery and
                  superior quality, we guarantee complete satisfaction with
                  every project.
                </p>
                <Button onClick={() => navigate("/about")}>Read More</Button>
              </Col>
            </Row>
          </Layout>
        </section>

        {/* About */}

        {/* Reviews */}
        {/* Reviews */}
        <OurServices />
        <OurPortfolio data={PortfolioImgs} />
        {/* <TestimonialsSection data={imgs} /> */}
        <FaqsSection />
        <ContactUs />
        <Footer />
      </LayoutBox>
      {/* <span className='scroll_down' id='cursor'>Scroll</span> */}
    </div>
  );
}
