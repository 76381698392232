import React, { useEffect, useState } from "react";
import { Layout, Menu, Segmented } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "./Header.module.css";
import Button from "../Button";
import Drawer from "react-modern-drawer";
import { isMobileViewHook } from "../../CustomHooks/isMobileViewHook";
import { HiBars3CenterLeft } from "react-icons/hi2";

const items = [
  { name: "Home", link: "/" },
  { name: "About", link: "/about" },
  { name: "Services", link: "/services" },
  { name: "Work", link: "/work" },
  { name: "Contact", link: "/contact" },
];

function CustomHeader({ containerClass }) {
  const [isShadow, setIsShadow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const path = useLocation()?.pathname + useLocation()?.hash;

  const navigate = useNavigate();
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 20) {
        setIsShadow(true);
      } else {
        setIsShadow(false);
      }
    });

    isMobileViewHook(setIsMobile);
  }, []);
  console.log({ isShadow });
  return (
    <Layout.Header
      className={`${classes.header} ${isShadow && classes.boxShadow}`}
    >
      <style>{`
      .ant-menu-item-selected{
        color:var(--clr-000000) !important;
      }
      .ant-menu-item-selected::after{
        border-bottom-color:var(--clr-000000) !important;
      }
      .ant-menu-horizontal{
        border-bottom:none;
      }
      `}</style>
      <div className={containerClass}>
        <h1
          className={`brand_logo ${classes.heading}`}
          onClick={() => navigate("/")}
        >
          TechFlow
          <span>Studio</span>
        </h1>
        {!isMobile && (
          <>
            <Menu theme="light" mode="horizontal" className={classes.links}>
              {items?.map((item) => (
                <Menu.Item
                  className={[
                    classes.link,
                    "fw-bold",
                    path == item?.link && classes.selected,
                  ].join(" ")}
                  onClick={() => navigate(item?.link)}
                >
                  {item?.name}
                </Menu.Item>
              ))}
            </Menu>
            <Button
              className={classes.btn}
              onClick={() => window.open("mailto:techflowstudio@gmail.com")}
            >
              Get In Touch
            </Button>
          </>
        )}
        {isMobile && (
          <HiBars3CenterLeft size={25} onClick={() => setIsOpen(!isOpen)} />
        )}
      </div>
      <CustomeDrawer setIsOpen={setIsOpen} isOpen={isOpen} />
    </Layout.Header>
  );
}

function CustomeDrawer({ isOpen, setIsOpen }) {
  const path = useLocation()?.pathname + useLocation()?.hash;
  const navigate = useNavigate();
  console.log({ p: path });

  return (
    <Drawer
      open={isOpen}
      onClose={() => setIsOpen(false)}
      direction="right"
      className={classes.drawer}
    >
      <div className={classes.drawerHeader}>
        <h6>Logo Here</h6>
      </div>
      {items?.map((item) => (
        <div
          className={[
            classes.sideLink,
            "fw-bold",
            path == item?.link && classes.selected,
          ].join(" ")}
          onClick={() => navigate(item?.link)}
        >
          {item?.name}
          {console.log({ match: path == item?.link })}
        </div>
      ))}
    </Drawer>
  );
}

export default CustomHeader;
