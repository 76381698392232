import React from "react";
import LayoutBox from "../../Components/Layout";
import { Col, Layout, Row } from "antd";
import classes from "./about.module.css";
import CustomHeader from "../../Components/Header";
import Footer from "../../Components/Footer";
import { toast } from "react-toastify";
import { Img4, Img5, Img6 } from "../../Constant/imagePath";

function About() {
  return (
    <>
      <CustomHeader containerClass={"custom_container"} />
      <Layout
        className={[
          "custom_container",
          classes.about_page,
          classes.bgTrans,
        ].join(" ")}
      >
        <Row>
          <Col md={24}>
            <div className={classes.about_content}>
              {/* <h1>
                TECH FLOW{" "}
                <span>
                  STUDIO
                  <span className={`${classes.pattern}`}></span>
                </span>
              </h1> */}
              <h5 className="">We are beyond the ordinary tech agency</h5>
            </div>
          </Col>

          <Col md={24}>
            <div className={classes.aboutImg1}>
              <div className={classes.about_img1}>
                <img src={Img4} />
              </div>
            </div>
          </Col>

          <Row>
            <Col md={16} className={classes.aboutMainContent} sm={24}>
              <h4>
                Techflowstudio is the ultimate software agency for website
                development, design, app development, content writing, and
                graphic design.
              </h4>
              <div className={classes.twoParagraphs}>
                <p>
                  With years of experience in the software development field,
                  our team of experts is constantly pushing the boundaries of
                  technology and design, delivering innovative and
                  cost-effective solutions.
                </p>
                <p>
                  We always challenge ourselves to come up with groundbreaking
                  approaches, and are committed to providing our clients with
                  the best service possible. With our timely delivery and
                  superior quality, we guarantee complete satisfaction with
                  every project.
                </p>
              </div>
            </Col>
            <Col md={8} sm={24} className={classes.w100}>
              <div className={classes.aboutImg2}>
                <div className={classes.about_img2}>
                  <img src={Img6} />
                </div>
              </div>
            </Col>
          </Row>
        </Row>
      </Layout>
      <Footer></Footer>
    </>
  );
}

export default About;
{
  /* <div className="about_img1">
  <img src={require("../../assets/images/about-3.png")} />
</div> */
}
