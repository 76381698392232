function isMobileViewHook(setIsMobile, val) {
  getWidth(setIsMobile);
  window.addEventListener("resize", () => {
    getWidth(setIsMobile);
  });
}

function getWidth(setIsMobile, val) {
  if (window.screen.width < 992 || window.innerWidth < 992) {
    setIsMobile(true);
  } else {
    setIsMobile(false);
  }
}

export { isMobileViewHook };
