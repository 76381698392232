import React from "react";
import classes from "./projectCard.module.css";
import ProjectImg from "../../assets/images/project.png";
import { BsArrowUpRightCircle } from "react-icons/bs";

function ProjectCard({ large }) {
  return (
    <div className={classes.card}>
      <div className={`${classes.imgDiv} ${classes.imgZoom}`}>
        <img src={ProjectImg} />
      </div>
      <BsArrowUpRightCircle className={classes.icon} />
      <div className={classes.details}>
        <h6 className={`fw-bold ff_neue ${large ? "large" : "small"}`}>
          ProjectCard
        </h6>
        <p className="fw-regular ff_neue">ProjectCard</p>
      </div>
    </div>
  );
}

export default ProjectCard;
