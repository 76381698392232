import React from "react";
import classes from "./Button.module.css";

function Button({ children, onClick, className }) {
  return (
    <button
      className={[classes.btn, className && className].join(" ")}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

export default Button;
