import React, { useEffect, useState } from "react";
import classes from "./contact.module.css";
import { Col, Layout, Row } from "antd";
import { toast } from "react-toastify";
import Button from "../../Components/Button";
import Input from "../../Components/Input";
import CustomHeader from "../../Components/Header";
import Footer from "../../Components/Footer";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../Constant/firebase";
import { getLocation } from "../../Constant/location";
import { Img4, Img5, Img6 } from "../../Constant/imagePath";
function Contact(props) {
  const colRef = collection(db, "contacts");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  async function submit() {
    const loc = await getLocation();
    const querySnapshot = await addDoc(colRef, {
      name,
      email,
      message,
      ...loc,
    });
    setName("");
    setEmail("");
    setMessage("");
    return toast.success("Submitted successfully");
  }

  return (
    <>
      <CustomHeader containerClass={"custom_container"} />
      <section className={classes.contactSection} {...props}>
        <Layout className={["custom_container", classes.bgTrans].join(" ")}>
          <Row
            gutter={[
              { md: 30, sm: 15, xs: 10 },
              { sm: 15, xs: 10 },
            ]}
          >
            <Col xs={24} className={`${classes.contactHeader}`}>
              <h1 className={`${classes.aboutBigHeading}`}>
                We listen you
                {/* <span className={`${classes.icon}`}>❋</span> */}
              </h1>
              {/* <a className={`${classes.contactEmail}`} href="">
                hello@techflow.com
              </a> */}
              {/* <div className={`${classes.pattern}`}>
                <h1>TECH FLOW STUDIO</h1>
              </div> */}
            </Col>


            <Col md={24}>
            <div className={classes.contactImg1}>
              <div className={classes.contact_img1}>
                <img src={Img4} />
              </div>
            </div>
          </Col>



            <Col md={12}>
              <h1 className={`${classes.bigHeading}`}>
                We're too popular right now ☺
              </h1>
              <h6 className={`${classes.smallHeading}`}>
                We tailor your ideas into reality. Come visit us and you will
                see that all are true.
              </h6>

              <div className={`${classes.socialLinks}`}>
                <a
                  className={`${classes.socialLink}`}
                  target={"_blank"}
                  href="https://www.facebook.com"
                >
                  Facebook
                </a>
                <a
                  className={`${classes.socialLink}`}
                  target={"_blank"}
                  href="https://www.twitter.com"
                >
                  Twitter
                </a>
                <a
                  className={`${classes.socialLink}`}
                  target={"_blank"}
                  href="https://www.instagram.com"
                >
                  Instagram
                </a>
                <a
                  className={`${classes.socialLink}`}
                  target={"_blank"}
                  href="https://www.linkedin.com"
                >
                  Linkedin
                </a>
              </div>
            </Col>
            <Col md={12}>
              <Row gutter={[{}, { sm: 15, xs: 10 }]}>
                <Col xs={24} md={24}>
                  <div className={`${classes.email_number}`}>
                    <h3 className={`${classes.email}`}>hello@gmail.com</h3>
                    <h3 className={`${classes.number}`}>+123 456789</h3>
                  </div>
                </Col>

                <Col xs={24} md={24}>
                  <div className={`${classes.contactForm}`}>
                    <Col xs={24} md={24}>
                      <Input
                        placeholder={"Enter name here..."}
                        // label={"Name"}
                        setter={setName}
                        value={name}
                      />
                    </Col>
                    <Col xs={24}>
                      <Input
                        placeholder={"Enter email here..."}
                        // label={"Email"}
                        setter={setEmail}
                        value={email}
                      />
                    </Col>
                    <Col xs={24}>
                      <Input
                        isTextArea
                        placeholder={"Enter your message here..."}
                        // label={"Message"}
                        setter={setMessage}
                        value={message}
                      />
                    </Col>
                    <Col md={24}>
                      <Button
                        onClick={() => {
                          const params = {
                            name,
                            message,
                            email,
                          };
                          for (let key in params) {
                            if (params[key] == "")
                              return toast.error("Please fill all the fields");
                          }
                          submit();
                        }}
                      >
                        Submit
                      </Button>
                    </Col>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Layout>
      </section>
      <Footer />
    </>
  );
}

export default Contact;
