import React from "react";
import classes from "./services.module.css";
import { Col, Layout, Row } from "antd";
import { toast } from "react-toastify";
import Button from "../../Components/Button";
import CustomHeader from "../../Components/Header";
import Footer from "../../Components/Footer";
import Accordion from "../../Components/Accordion";
import { Img2 } from "../../Constant/imagePath";

function Services(props) {
  const data = [
    {
      title: "Web Development",
      description:
        "Our expert web developers use the latest technology to create custom websites that are responsive, user-friendly, and tailored to your business needs.",
    },
    {
      title: "Mobile Development",
      description:
        "We create mobile applications that are intuitive, engaging, and designed to meet your business objectives, ensuring a seamless user experience on any device.",
    },
    {
      title: "WordPress Development",
      description:
        "We specialize in WordPress development, offering a range of services, from custom theme design to plugin development, to help you build a website that stands out.",
    },
    {
      title: "Graphic Designing",
      description:
        "Our talented graphic designers create visually stunning designs for your brand that communicate your message effectively and engage your audience.",
    },
    {
      title: "Search Engine Optimization",
      description:
        "Our SEO experts use proven strategies to increase your website's visibility in search engine rankings, helping you attract more traffic and leads to your website.",
    },
    {
      title: "Digital Marketing",
      description:
        "Our digital marketing services include social media marketing, email marketing, and paid advertising to help you reach your target audience and achieve your business goals.",
    },
    {
      title: "Brand Identity",
      description:
        "We help you create a cohesive brand identity that reflects your business values and goals, ensuring consistency across all your marketing channels and materials.",
    },
  ];
  return (
    <>
      <CustomHeader containerClass={"custom_container"} />
      <section className={classes.servicePage} {...props}>
        <Layout className={["custom_container", classes.bgTrans].join(" ")}>
          <Row
            gutter={[
              { md: 30, sm: 15, xs: 10 },
              { sm: 15, xs: 10 },
            ]}
          >
            <Col md={24}>
              <div className={classes.service_content}>
                <h1>What we do</h1>

                <Row className={classes.row}>
                  <Col md={14}>
                    <h2 className={classes.serviceIntroContent}>
                      Our team of experts creates visually stunning websites
                      that are optimized for performance, usability, and search
                      engines.
                    </h2>
                  </Col>
                  <Col md={10}>
                    <div className={classes.serviceIntroImg}>
                      <img src={Img2} />
                    </div>
                  </Col>
                </Row>
                <div className={classes.serviceIntroContent}></div>
              </div>
            </Col>
            <Col md={24}>
              <div className={classes.goodAt}>
                <h2>We are good at</h2>
              </div>
              <div className={`${classes.accordionWrapper}`}>
                <Accordion data={data} />
              </div>
            </Col>
            <Col md={12}>
              <Row gutter={[{}, { sm: 15, xs: 10 }]}></Row>
            </Col>
          </Row>
        </Layout>
      </section>
      <Footer />
    </>
  );
}

export default Services;
