import { Layout } from "antd";
import React from "react";
import Accordion from "../../Components/Accordion";
import Button from "../../Components/Button";
import classes from "./faqs.module.css";

function FaqsSection({ containerClass = "", ...props }) {
  const data = [
    {
      title: "What services does your web agency offer?",
      description:
        "Our web agency offers a wide range of services including website design and development, e-commerce solutions, search engine optimization, content creation, and digital marketing.",
    },
    { title: "How long does it take to develop a website?",
     description: "The time it takes to develop a website can vary depending on the scope and complexity of the project. On average, it takes 4-8 weeks to develop a basic website and 8-12 weeks for a more complex one." },
    { title: "Can you provide an estimate for my project?",
     description: "Yes, we can provide you with a free estimate for your project after understanding your requirements and the scope of work." },
    { title: "Do you work with all types of businesses?",
     description: "Yes, we work with businesses of all sizes, from small startups to large enterprises." },
     { title: "Do you provide ongoing support for the website?",
     description: "Yes, we offer a range of ongoing support and maintenance packages to ensure that your website remains up-to-date and functional." },
     { title: "Do you provide a warranty for your work?",
     description: "Yes, we provide a warranty for our work, covering any bugs or technical issues that may arise after the website has been launched." },
     { title: "How do you handle project changes during development?",
     description: "We have a clear project management process in place to handle any changes or updates during the development process. All changes will be discussed, tracked, and approved by the client before they are implemented." },
     { title: "How do you ensure the website is secure?",
     description: "We implement a range of security measures to protect your website and data, including regular software updates and backups. Additionally, we can provide SSL certificates and other security options to further secure your website." },
  ];
  return (
    <section className={classes.faqsSection} {...props}>
      <Layout
        className={["custom_container", classes.bgTrans, containerClass].join(
          " "
        )}
      >
        <h5 className={`${classes.faqHeading}`}>Frequently Asked Questions</h5>
        <div className={`${classes.accordionWrapper}`}>
          <Accordion data={data} />
        </div>
      </Layout>
    </section>
  );
}

export default FaqsSection;
