import { Col, Layout, Row } from "antd";
import React from "react";
import ProjectCard from "../../Components/ProjectCard";
import classes from "./OurPortfolio.module.css";

function OurPortfolio({ data, ...props }) {
  return (
    <div
      className={`${classes.section} ${classes.portfolioSection}`}
      {...props}
    >
      <Layout className={["custom_container", classes.bgTrans].join(" ")}>
        <h5 className={`${classes.aboutBigHeading}`}>Featured Projects</h5>
        <p className={`${classes.subHeading}`}>we’re proud of ●</p>
        {/* <Row gutter={[20, { md: 20, sm: 20, xs: 20 }]}>
          {data?.map((item) => (
            <Col md={6} sm={24}>
              
              <ProjectCard />
            </Col>
          ))}
        </Row> */}
        <Row gutter={[20, { md: 20, sm: 20, xs: 20 }]}>
          <Col md={16} sm={24}>
            <div className={`${classes.BigCard}`}>
              <ProjectCard large={true} />
            </div>
          </Col>
          <Col md={8} sm={24}>
            <Row gutter={[5, { md: 8, sm: 20, xs: 20 }]}>
              <Col md={24}>
                <div className={`${classes.smallCard}`}>
                  <ProjectCard />
                </div>
              </Col>
              <Col md={24}>
                <div className={`${classes.smallCard}`}>
                  <ProjectCard />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className={classes.mt20} gutter={[20, { md: 20, sm: 20, xs: 20 }]}>
          <Col md={8} sm={24}>
            <Row gutter={[5, { md: 8, sm: 20, xs: 20 }]}>
              <Col md={24}>
                <div className={`${classes.smallCard}`}>
                  <ProjectCard />
                </div>
              </Col>
              <Col md={24}>
                <div className={`${classes.smallCard}`}>
                  <ProjectCard />
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={16} sm={24}>
            <div className={`${classes.BigCard}`}>
              <ProjectCard />
            </div>
          </Col>
        </Row>
      </Layout>
    </div>
  );
}

export default OurPortfolio;
