import { Col, Row, Layout } from "antd";
import React, { useState } from "react";
import { PortfolioData } from "../../Constant/data";
import { DemoImg } from "../../Constant/imagePath";
import classes from "./ourServices.module.css";
import Button from "../../Components/Button";
import { useNavigate } from "react-router-dom";
const Box = ({ selected, title, onClick }) => {
  return (
    <div
      className={[classes.box, selected && classes.selected].join(" ")}
      onClick={onClick}
    >
      {title}
    </div>
  );
};
function OurServices(props) {
  const navigate = useNavigate();
  const [selected, setSelected] = useState(PortfolioData[0]);
  return (
    // <section  className={classes.section} {...props}>
    //   <div className="custom_container">
    //     <Row gutter={[30, 0]}>
    //       <Col md={24}>
    //         <h5>Our Services</h5>
    //       </Col>
    //       <Col md={8}>
    //         {PortfolioData?.map((item, i) => (
    //           <Box
    //             key={i}
    //             selected={item?.title == selected?.title}
    //             title={item?.title}
    //             onClick={() => setSelected(item)}
    //           />
    //         ))}
    //       </Col>
    //       <Col md={16}>
    //         <div>
    //           <div className={classes.imgDiv}>
    //             <img src={selected?.image ? selected?.image : DemoImg} />
    //           </div>
    //           <h3 className={classes.mt20}>{selected?.title}</h3>
    //           <p className={classes.mt20}>{selected?.description}</p>
    //         </div>
    //       </Col>
    //     </Row>
    //   </div>
    // </section>

    <section className={classes.serviceSection} {...props}>
      {/* <Layout className={["custom_container", classes.bgTrans].join(" ")}> */}
      <Layout className={`custom_container ${classes.bgTrans}`}>
        <Row
          gutter={[
            { md: 30, sm: 15, xs: 10 },
            { sm: 15, xs: 10 },
          ]}
        >
          <Col md={12} sm={24} className={classes.leftCol}>
            <h1 className={`${classes.aboutBigHeading}`}>
              What we do <span className={`${classes.icon}`}>✳</span>
            </h1>
            <div>
              <p>
                Our web agency provides a full suite of digital services to help
                businesses establish and enhance their online presence. Our team
                of experts offers creative services. Let us help you achieve
                your online goals and drive success with a seamless and cohesive
                digital strategy.
              </p>
              <div className={`${classes.serviceBtn}`}>
                <Button onClick={() => navigate("/services")}>Read More</Button>
              </div>
            </div>
          </Col>
          <Col md={12} sm={24}>
            <h5>Creative Services</h5>
            <hr />

            <div className={`${classes.servicesList}`}>
              <ul>
                <li>
                  <h3>
                    <a>
                      <span className={`${classes.servNumber}`}>01</span> Web
                      Development
                    </a>
                  </h3>
                </li>
                <li>
                  <h3>
                    <a>
                      <span className={`${classes.servNumber}`}>02</span> Mobile
                      Development
                    </a>
                  </h3>
                </li>
                <li>
                  <h3>
                    <a>
                      <span className={`${classes.servNumber}`}>03</span>{" "}
                      WordPress Development
                    </a>
                  </h3>
                </li>
                <li>
                  <h3>
                    <a>
                      <span className={`${classes.servNumber}`}>04</span>{" "}
                      Graphic Designing
                    </a>
                  </h3>
                </li>
                <li>
                  <h3>
                    <a>
                      <span className={`${classes.servNumber}`}>05</span> Search
                      Engine Optimization
                    </a>
                  </h3>
                </li>
                <li>
                  <h3>
                    <a>
                      <span className={`${classes.servNumber}`}>06</span>{" "}
                      Digital Marketing
                    </a>
                  </h3>
                </li>
                <li>
                  <h3>
                    <a>
                      <span className={`${classes.servNumber}`}>07</span> Brand
                      Identity
                    </a>
                  </h3>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Layout>
    </section>
  );
}

export default OurServices;
