import React from "react";
import classes from "./Input.module.css";

function Input({
  label,
  placeholder,
  state,
  setter,
  type = "text",
  isTextArea,
}) {
  return (
    <div className={classes.inputDiv}>
      {label && <label>{label}</label>}
      {!isTextArea ? (
        <input
          type={type}
          placeholder={placeholder}
          value={state}
          onChange={(e) => setter(e.target.value)}
        />
      ) : (
        <textarea
          rows={5}
          placeholder={placeholder}
          value={state}
          onChange={(e) => setter(e.target.value)}
        />
      )}
    </div>
  );
}

export default Input;
