import React from "react";
import classes from "./whatWeDoCard.module.css";
import About from "../../assets/images/about-2.png";
import { HiOutlineCode } from "react-icons/hi";

function WhatWeDoCard({
  data = {
    description:
      "Take advantage of advanced cloud services to improve scalability, reliability. and cost-efficiency while adding new capabilities to your application",
    title: "Web Development",
  },
}) {
  return (
    <div className={classes.card}>
      <span>
        <HiOutlineCode />
      </span>
      <h6>{data?.title}</h6>
      <p>{data?.description}</p>
      <div
        style={{
          background: `url(https://www.abtach.com/assets/images/team-overlay.png) repeat-x left bottom`,
          position: "absolute",
          zIndex: 9,
          width: "91.4%",
          height: "78%",
          left: "16px",
          paddingTop: "82%",
          top: "26px",
        }}
      />
    </div>
  );
}

export default WhatWeDoCard;
