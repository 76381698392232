import { Segmented } from "antd";
import React from "react";
import Button from "../../Components/Button";
import classes from "./demo.module.css";

function Demo() {
  return (
    <div>
      <section className={classes.featuredProjectsSection}>
        <Segmented
          options={["WEB DEVELOPMENT", "APP DEVELOPMENT", "GRAPHIC DESIGN"]}
        />
        <div className={classes.spBtw}>
          <div>
            <h4>FEATURED PROJECTS</h4>
            <p>Take a look at our most successful projects.</p>
          </div>
          <Button>View More</Button>
        </div>
      </section>
    </div>
  );
}

export default Demo;
