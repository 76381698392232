import React, { useState } from "react";
import { Collapse } from "antd";
import classes from "./accordion.module.css";
const { Panel } = Collapse;

function Accordion({ data }) {
  const [isOpen, setIsOpen] = useState(1);
  return (
    <>
      <style>
        {`
            .ant-collapse-icon-position-start.ant-collapse{
                background-color:transparent !important;
            }
            .ant-collapse-header-text{
                font-size:32px !important;
            }
            .ant-collapse-header{
              align-items:center !important;
            }
            @media screen and (max-width:1024px){
              .ant-collapse-header-text{
                font-size:24px !important;
              }
            }
            @media screen and (max-width:767px){
              .ant-collapse-header-text{
                font-size:20px !important;
              }
            }
            `}
      </style>
      <Collapse
        className={`${classes.accordionTitle}`}
        activeKey={isOpen}
        onChange={(e) => {
          console.log({ e });
          setIsOpen(e[e?.length - 1]);
        }}
      >
        {data?.map((item, key) => (
          <Panel key={++key} header={item?.title}>
            <p className={`${classes.accordionText}`}>{item?.description}</p>
          </Panel>
        ))}
      </Collapse>
    </>
  );
}

export default Accordion;
