import { Col, Layout, Row } from "antd";
import React from "react";
import ReactElasticCarousel from "react-elastic-carousel";
import WhatWeDoCard from "../../Components/WhatWeDoCard";
import classes from "./Testimonials.module.css";

function TestimonialsSection({ data, ...props }) {
  return (
    <section className={classes.reviewsSection} {...props}>
      <style>{`
      .rec-dot{
        box-shadow:unset;
        border:2px solid var(--text-black-color);
        background-color: var(--text-black-color);

    }
    .rec-dot_active{
        box-shadow:unset;
        background:var(--main-color);
        border:2px solid var(--main-color);
        width:25px;
        border-radius:10px
    }
  
    .rec-dot:hover, .rec-dot:focus{
        box-shadow:unset;
    }
    `}</style>
      <Layout className={["custom_container", classes.bgTrans].join(" ")}>
        <Row>
          <Col md={24}>
            <h5>What our customer says</h5>
          </Col>
          <Col md={24}>
            <ReactElasticCarousel
              showArrows={false}
              breakPoints={[
                {
                  width: 400,
                  itemsToScroll: 1,
                  itemsToShow: 3,
                  itemPadding: [10, 20],
                },
              ]}
              autoPlaySpeed={1500}
              enableAutoPlay={true}
            >
              {data?.map((item) => (
                <div
                  className={classes.carouselImg}
                  data-scroll
                  data-scroll-direction={"horizontal"}
                  data-scroll-speed={"3"}
                >
                  <img src={item} />
                </div>
              ))}
            </ReactElasticCarousel>
          </Col>
        </Row>
      </Layout>
    </section>
  );
}

export default TestimonialsSection;
