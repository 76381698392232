import { Layout } from "antd";
import React from "react";
import { slide as Menu } from "react-burger-menu";
import Header from "../Header";
import classes from "./Layout.module.css";

function LayoutBox({ children, containerClass }) {
  return (
    <Layout className={classes.content}>
      <Header containerClass={containerClass} />
      <Layout.Content className={classes.content}>{children}</Layout.Content>
    </Layout>
  );
}

export default LayoutBox;
