import { Col, Row } from "antd";
import React from "react";
import { FaFacebook } from "react-icons/fa";
import { AiOutlineTwitter, AiFillInstagram } from "react-icons/ai";
import classes from "./Footer.module.scss";
import { useNavigate } from "react-router-dom";

function Footer(props) {
  const navigate = useNavigate();
  const links = [
    { title: "Home", link: "/" },
    { title: "About", link: "/about" },
    { title: "Services", link: "/services" },
    { title: "Work", link: "/work" },
    { title: "Contact Us", link: "/contact" },
  ];
  const services = [
    { title: "Website Development" },
    { title: "Application Development" },
    { title: "Graphic Designing" },
    { title: "Social Media Marketing" },
  ];
  return (
    <div className={classes.footer} {...props}>
      <div className={`${classes.container} custom_container`}>
        <Row gutter={[{ sm: 10 }, { sm: 30, xs: 50 }]}>
          <Col lg={9} sm={12} xs={24}>
            {/* <h5 className={`brand_logo ${classes.logo}`}>
              Tech Flow<span>Studio</span>
            </h5> */}
            <p className={`${classes.footerAbout} fw-bold`}>
              Techflowstudio is the ultimate software agency for website
              development, design, app development, content writing, and graphic
              design.
            </p>
            <ul className={classes.socialIcons}>
              <li>
                <AiOutlineTwitter size={23} color={"var(--main-color)"} />
              </li>
              <li>
                <FaFacebook size={20} color={"var(--main-color)"} />
              </li>
              <li>
                <AiFillInstagram size={22} color={"var(--main-color)"} />
              </li>
            </ul>
          </Col>
          <Col lg={6} sm={12} xs={24}>
            <h6 className="fw-bold">Quick Links</h6>
            <ul>
              {links?.map((item) => (
                <li
                  className={`fw-medium ${classes.link}`}
                  onClick={() => navigate(item?.link)}
                >
                  {item?.title}
                </li>
              ))}
            </ul>
          </Col>
          <Col lg={9} sm={12} xs={24}>
            <h6 className="fw-bold">Services</h6>
            <ul className={classes.services}>
              {services?.map((item) => (
                <li className="fw-medium">{item?.title}</li>
              ))}
            </ul>
          </Col>
        </Row>
        <div className={classes.endLine}>
          <h1>Techflowstudio</h1>
        </div>
        <div className={classes.spBtw}>
          <p>2023 - techflow studio</p>
          <p>Design & Developed by - Techflow Studio</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
