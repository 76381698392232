import React from "react";
import classes from "./work.module.css";
import { Col, Layout, Row } from "antd";
import { toast } from "react-toastify";
import Button from "../../Components/Button";
import CustomHeader from "../../Components/Header";
import Footer from "../../Components/Footer";
import ProjectImg from "../../assets/images/project.png";
import { BsArrowUpRightCircle } from "react-icons/bs";
import ProjectCard from "../../Components/ProjectCard";

function Work(props) {
  return (
    <>
      <CustomHeader containerClass={"custom_container"} />
      <section className={classes.workPage} {...props}>
        <Layout className={["custom_container", classes.bgTrans].join(" ")}>
          <Row
            gutter={[
              { md: 30, sm: 15, xs: 10 },
              { sm: 15, xs: 10 },
            ]}
          >
            <Col xs={24}>
              <div className={`${classes.workPageHeader}`}>
                <h1>WORK</h1>
                <p>
                  the results of our expertise showcases the creativity,
                  innovation, and quality that we bring to every project.
                </p>
              </div>
            </Col>
            <Col md={24}>
              <div className={`${classes.projectCardList}`}>
                <div
                  className={`${classes.projectCard} ${classes.largeProjectCard}`}
                >
                  <div className={`${classes.projectCardWrapper}`}>
                    <ProjectCard></ProjectCard>
                  </div>
                </div>
                <div className={`${classes.projectCard}`}>
                  <div className={`${classes.projectCardWrapper}`}>
                    <ProjectCard></ProjectCard>
                  </div>
                </div>
                <div className={`${classes.projectCard}`}>
                  <div className={`${classes.projectCardWrapper}`}>
                    <ProjectCard></ProjectCard>
                  </div>
                </div>
                <div className={`${classes.projectCard}`}>
                  <div className={`${classes.projectCardWrapper}`}>
                    <ProjectCard></ProjectCard>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Layout>
      </section>
      <Footer />
    </>
  );
}

export default Work;
